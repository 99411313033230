import React, { useState, useContext } from "react";
import { GlobalContext } from "../data/GlobalContext";

import { Link } from "react-router-dom";
import CountUp from "react-countup";
import { Col, Container, Row } from "react-bootstrap";
import VisibilitySensor from "react-visibility-sensor";


import { AiTwotoneHome } from "react-icons/ai";



const AboutTwo = ({ images }) => {

    const dbData = useContext(GlobalContext);

    const goToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth',
        });
    };

    const [counter, setCounter] = useState({
        startCounter: false
    });

    const onVisibilityChange = (isVisible) => {
        if (isVisible) {
            setCounter({ startCounter: true });
        }
    };

    return (
        <section className="about-counter">
            <Container>
                <Row className="d-flex flex-column-reverse flex-lg-row">
                    <Col sm={12} lg={6}>
                        <div className="block-title">
                            <h3 className="text-capitalize">
                                a little about us
                            </h3>
                        </div>
                        <p className="about-counter__text">
                            {dbData.dbAbout[0].text}
                        </p>
                        <ul className="list-unstyled ul-list-one">
                            {
                                dbData.dbAbout[0].list.length >= 1 ?
                                    dbData.dbAbout[0].list.map((item, index) => {
                                        return (
                                            <li key={index}>
                                                {item}
                                            </li>
                                        );
                                    })
                                    : null
                            }
                        </ul>
                        <div className="btn-wrap mb-5">
                            <Link
                                className="scroll-to-target thm-btn-inverse text-capitalize"
                                to="/contact"
                                onClick={goToTop}
                            >
                                free estimate
                            </Link>
                        </div>
                        <div className="about-counter__count">
                            <h3 className="odometer">
                                {
                                    dbData.dbPrincipal.exprYears === "many" ?
                                        <VisibilitySensor
                                            onChange={onVisibilityChange}
                                            offset={{ top: 10 }}
                                            delayedCall
                                        >
                                            <CountUp end={counter.startCounter ? 100 : 0} />
                                        </VisibilitySensor>
                                        :
                                        <VisibilitySensor
                                            onChange={onVisibilityChange}
                                            offset={{ top: 10 }}
                                            delayedCall
                                        >
                                            <CountUp end={counter.startCounter ? dbData.dbPrincipal.exprYears : 0} />
                                        </VisibilitySensor>
                                }
                            </h3>
                            <div>
                                {
                                    dbData.dbPrincipal.exprYears === "many" ?
                                        <p className="text-capitalize">
                                            professionals
                                        </p>
                                        :
                                        <p className="text-capitalize">
                                            years of
                                            <br />
                                            experience
                                        </p>
                                }
                            </div>
                        </div>
                    </Col>
                    <Col sm={12} lg={6}>
                        <div className="about-counter__image clearfix">
                            <div className="about-counter__image-content">
                                <AiTwotoneHome fontSize={100} color='white' />
                                <p>{dbData.dbSlogan[2].slogan}</p>
                            </div>
                            <img src={images} alt="photoStock" className="float-left aboutTwo__img" />
                        </div>
                    </Col>
                </Row>
            </Container>
        </section>
    );
}

export default AboutTwo;
