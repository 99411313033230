import { useContext } from "react";
import { GlobalContext } from "../data/GlobalContext";
import { Container, Row, Col } from "react-bootstrap";
import { Link } from "react-router-dom";
import { Link as ScrollLink } from "react-scroll";
import * as FaIcons from 'react-icons/fa';
import BotonWhatsapp from '../botonWhatsapp/BotonWhatsapp';
import ContadorVisita from "../contadorVisita/ContadorVisita";


const imgUrl = require.context('../../assets/images', true);

const Footer = () => {
    const dbData = useContext(GlobalContext);
    const goToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth',
        });
    };

    //sub menu
    const subitems = dbData.dbServices.map((item, index) => {
        return (
            {
                link: `/${item.name.replace(" ", "-").toLowerCase()}`,
                name: item.name,
            }
        )
    })

    // Menu
    const dbMenu = [
        {
            name: `Home`,
            link: `/`,
        },
        {
            name: `About`,
            link: `/about`,
        },
        {
            name: `Services`,
            link: `/services`,
            child: dbData.autoGntLandingFromService,
            submenu: [
                ...subitems
            ]
        },
        {
            name: `Gallery`,
            link: `/gallery`,
        },
        {
            name: `Contact`,
            link: `/contact`,
        },
    ]

    return (
        <section className="site-footer">
            <div className="main-footer pt-142 pb-80">
                <Container>
                    <Row className="main-Footer__incoContent">
                        <Col lg={3} md={6} sm={12}>
                            <div className="footer-widget mb-40 footer-widget__about">
                                <Link to='/'>
                                    <img src={`${dbData.dbPrincipal.logo}`} width="250" alt="logo" />
                                </Link>
                                <p></p>
                                <ul className="list-unstyled footer-widget__contact">
                                    <li>
                                        <h5 style={{color:'white'}}>Phone</h5>
                                        {
                                            dbData.dbPrincipal.phones.map((item, index) => {
                                                return (
                                                    <div>
                                                        <a key={index} href={`tel:+1${item.phone}`}>
                                                            <i className="azino-icon-telephone"></i>
                                                            {item.phone}
                                                        </a>
                                                    </div>
                                                )
                                            })
                                        }
                                    </li>
                                </ul>
                                <div className="footer-social">
                                    {
                                        dbData.dbSocialMedia.redes.map((item, index) => {
                                            const Social =
                                                FaIcons[
                                                item.icon
                                                ];
                                            return (
                                                <>
                                                    {
                                                        item.name !== ' ' ?
                                                            <a
                                                                key={index}
                                                                href={item.url}
                                                                target="_blank"
                                                                rel="noopener noreferrer"
                                                            >
                                                                <Social />
                                                            </a>
                                                            : null

                                                    }
                                                </>
                                            )
                                        })
                                    }
                                </div>
                            </div>
                        </Col>
                        <Col lg={3} md={6} sm={12}>
                            <div className="footer-widget footer-widget__link mb-40">
                                <h3 className="footer-widget__title">Services</h3>
                                <ul className="list-unstyled footer-widget__link-list">
                                    {

                                        dbData.autoGntLandingFromService ?
                                            dbData.dbServices.slice(0, 8).map((item, index) => {
                                                return (

                                                    <li key={index}>
                                                        <Link to={`/${item.name.replace(" ", "-").toLowerCase()}`} onClick={goToTop}>
                                                            {item.name}
                                                        </Link>
                                                    </li>
                                                )
                                            }) :
                                            dbData.dbServices.slice(0, 8).map((item, index) => {
                                                return (

                                                    <li key={index}>

                                                        <Link to='/services' onClick={goToTop}>
                                                            {item.name}
                                                        </Link>
                                                    </li>
                                                )
                                            })

                                    }

                                </ul>
                            </div>
                        </Col>
                        <Col lg={3} md={6} sm={12}>
                            <div className="footer-widget mb-40 footer-widget__blog">
                                <h3 className="footer-widget__title">Contact</h3>
                                <ul className="list-unstyled footer-widget__blog">
                                    <li>
                                        <img src={imgUrl(`./stock/calendar.png`)} width='50' alt="" />
                                        <p className="text-capitalize">Workdays</p>
                                        <h3>
                                            {dbData.dbPrincipal.workdays[0].day}
                                        </h3>
                                    </li>
                                    <li>
                                        <img src={imgUrl(`./stock/clock.png`)} width='50' alt="" />
                                        <p className="text-capitalize">work hours</p>
                                        <h3>
                                            {dbData.dbPrincipal.workHours[0].hour}
                                        </h3>
                                    </li>
                                </ul>
                                {
                                    dbData.dbPrincipal.paymentMethod === 'we accept cash & checks' ?
                                        <h3 className="footer-widget__payment">{dbData.dbPrincipal.paymentMethod}</h3>
                                        :
                                        <>
                                            <h3 className="footer-widget__payment">{dbData.dbPrincipal.paymentMethod}</h3>
                                            <img src={imgUrl('./directorios/credit-cards-zelle.png')} alt='payment image' width={'100%'} />
                                        </>
                                }

                            </div>
                        </Col>
                        <Col lg={3} md={6} sm={12}>
                            <div className="footer-widget mb-40 footer-widget__newsletter">
                                <h3 className="footer-widget__title">About</h3>
                                <p>{dbData.dbAbout[0].text.substring(0, 167)}.</p>
                                <div className="d-flex  justify-content-end mt-3">
                                    <Link className="thm-btn dynamic-radius text-capitalize" to="/contact" onClick={goToTop}>
                                        free estimate
                                    </Link>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>
            <div className="footer-bottom">
                <div className="container">
                    <ScrollLink
                        to="wrapper"
                        smooth={true}
                        duration={500}
                        className="scroll-to-top"
                    >
                        <i className="far fa-angle-up"></i>
                    </ScrollLink>
                    <p>© Copyright 2022 by {dbData.dbPrincipal.name}</p>
                    <ul className="footerMenu">
                        {
                            dbMenu.map((items, index) => {
                                return (
                                    <li key={index}>
                                        <Link
                                            to={items.link}
                                            onClick={goToTop}
                                        >
                                            {items.name}
                                        </Link>
                                    </li>
                                )
                            })
                        }
                    </ul>
                </div>
            </div>
            <BotonWhatsapp />
            <ContadorVisita />
        </section >
    )
}

export default Footer;